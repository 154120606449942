import { useEffect } from "react";
import useUserInfo from "./useUserInfo";
import { cutLinkedin, isValidEmail } from "Utils";
import axios from "axios";
import { BASE_SERVER_URL, SERVER_URL } from "api";
import { useDispatch } from "react-redux";
import {
  setAudience,
  setBillingInfo,
  setDefaultAssistant,
  setInterviews,
  setIsAuth,
  setIsCheckingActivity,
  setIsCheckingAudience,
  setIsCheckingAuth,
  setIsCheckingSubscription,
  setIsSignedUp,
  setIsSlim,
  setPersonalized,
  setProfile,
} from "store/mainSlice";
import useCookie from "./useCookie";
import { setPodcastUrl } from "store/interviewSlice";
import useInterview from "./useInterview";
// use only App.js
const useAutoManage = () => {
  const dispatch = useDispatch();
  const { profile, isAuth } = useUserInfo();
  const { interviewId } = useInterview();
  const { email } = profile;

  // fetch user info
  useEffect(() => {
    const fetchUserInfo = async () => {
      dispatch(setIsCheckingAuth(true));
      try {
        const response = await axios.get(
          `${BASE_SERVER_URL}/api/v2/users/assistant/profile`
        );
        const profile = response.data.profile;
        dispatch(setIsAuth(true));
        let newLinkedin =
          profile.linkedinProfileUrl ||
          profile.linkedInProfileUrl ||
          profile.linkedin ||
          "";
        let linkedinProfile = {};
        if (newLinkedin) {
          newLinkedin = cutLinkedin(newLinkedin);
          const response = await axios.get(
            `${SERVER_URL}/services/linkedin/${newLinkedin}`
          );
          linkedinProfile = response.data;
        }
        dispatch(
          setProfile({
            ...linkedinProfile,
            firstName: profile.name,
            email: profile.email,
            picture: linkedinProfile.picture || profile.picture,
            linkedin: newLinkedin,
            linkedinConnected: !!profile.linkedinConnected,
            personalized: !!profile.isPersonalized,
          })
        );
      } catch (error) {
        console.log(error);
        dispatch(setIsAuth(false));
      }
      dispatch(setIsCheckingAuth(false));
    };
    fetchUserInfo();
  }, [dispatch]);

  // fetch user interviews
  useEffect(() => {
    const fetchUserActivity = async () => {
      const isEmailInvalid = !email || !email.trim() || !isValidEmail(email);
      if (isEmailInvalid) {
        dispatch(setIsCheckingActivity(false));
        return;
      }
      dispatch(setIsCheckingActivity(true));
      try {
        const response = await axios.post(`${SERVER_URL}/interview-sessions/`, {
          email,
        });
        dispatch(setInterviews(response.data.items));
      } catch (error) {
        console.log(error);
        dispatch(setInterviews([]));
      }
      dispatch(setIsCheckingActivity(false));
    };
    const fetchDefaultAssistant = async () => {
      const isEmailInvalid = !email || !email.trim() || !isValidEmail(email);
      if (isEmailInvalid) {
        return;
      }
      try {
        const response = await axios.get(`${SERVER_URL}/services/assistant`, {
          params: { email },
        });
        dispatch(
          setDefaultAssistant({
            name: response.data.name,
            picture: response.data.picture,
          })
        );
      } catch (error) {
        console.log(error);
        dispatch(setDefaultAssistant(null));
      }
    };
    fetchUserActivity();
    fetchDefaultAssistant();
  }, [dispatch, email]);
  useEffect(() => {
    const fetchPersonalized = async () => {
      if (email.trim().length === 0) {
        dispatch(setPersonalized(false));
        return;
      }
      try {
        const response = await axios.get(
          `${SERVER_URL}/personalization/check`,
          { params: { email } }
        );
        dispatch(setPersonalized(response.data.isPersonalized));
      } catch (error) {
        console.log(error);
        dispatch(setPersonalized(false));
      }
    };
    fetchPersonalized();
  }, [dispatch, email]);

  useEffect(() => {
    const checkSignedUp = async (email) => {
      if (isAuth) {
        dispatch(setIsSignedUp(true));
        return true;
      }
      if (email.trim().length === 0) {
        dispatch(setIsSignedUp(false));
        return false;
      }
      try {
        //   const result = await fetchLinkedinProfile(linkedin);
        //   if (!result) return;
        const response = await axios.post(
          `${BASE_SERVER_URL}/api/v2/users/assistant/check`,
          { email }
        );
        if (response.data.ok) {
          dispatch(setIsSignedUp(true));
        }
        return true;
      } catch (error) {
        console.log(error);
        dispatch(setIsSignedUp(false));
      }
    };
    checkSignedUp(email);
  }, [dispatch, email, isAuth]);

  const { setCookiePayed, removeCookiePayed, isSubscribed } = useCookie();

  useEffect(() => {
    const fetchUserBilling = async () => {
      if (!isAuth) {
        dispatch(setIsCheckingSubscription(false));
        dispatch(setBillingInfo(null));
        return;
      }
      if (isSubscribed) {
        dispatch(setIsCheckingSubscription(false));
        return;
      }
      dispatch(setIsCheckingSubscription(true));
      try {
        const response = await axios.get(`${SERVER_URL}/billing/info`);
        dispatch(setBillingInfo(response.data));
        if (!!response.data && !response.data.isCanceled) setCookiePayed(email);
      } catch (error) {
        console.log(error);
        dispatch(setBillingInfo(null));
        removeCookiePayed();
      }
      dispatch(setIsCheckingSubscription(false));
    };
    fetchUserBilling();
  }, [
    dispatch,
    email,
    isAuth,
    isSubscribed,
    removeCookiePayed,
    setCookiePayed,
  ]);

  useEffect(() => {
    // Update width on window resize
    const handleResize = () => {
      if (window.innerWidth > 768) {
        dispatch(setIsSlim(false));
      } else dispatch(setIsSlim(true));
    };
    handleResize();
    // Add event listener
    window.addEventListener("resize", handleResize);
    window.addEventListener("DOMContentLoaded", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("DOMContentLoaded", handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    const fetchAudience = async () => {
      try {
        dispatch(setAudience([]));
        dispatch(setIsCheckingAudience(true));
        const response = await axios.get(
          `${SERVER_URL}/audience?email=${encodeURIComponent(email)}`
        );
        dispatch(setAudience(response.data.audience));
        dispatch(setIsCheckingAudience(false));
      } catch (error) {
        console.log(error);
      }
    };
    fetchAudience();
  }, [dispatch, email]);

  useEffect(() => {
    const fetchInterview = async (id) => {
      try {
        const response = await axios.get(
          `${SERVER_URL}/interview-sessions/${id}/data`
        );
        const interview = response.data;
        dispatch(setPodcastUrl(interview.podcastUrl));
      } catch (error) {
        console.log(error);
        console.log(
          "Failed to load the podcast of this interview. Please refresh the page."
        );
      }
    };
    if (interviewId) fetchInterview(interviewId);
  }, [dispatch, interviewId]);
};

export default useAutoManage;
