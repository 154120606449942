import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import sidebarIcon from "assets/images/sidebar/sidebar-icon.svg";
import fullLogo from "assets/images/sidebar/full-logo.svg";
import burgerIcon from "assets/images/sidebar/burger-icon.svg";
import interviewIcon from "assets/images/sidebar/file-heart.svg";
import iconJessica from "assets/images/sidebar/icon-jessica.png";
import { KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";

import useUserInfo from "hooks/useUserInfo";
import { setFetchedPosts } from "store/postSlice";
import { useDispatch } from "react-redux";
import { initInterviewData } from "store/interviewSlice";
import useInterview from "hooks/useInterview";
import useCustomTheme from "hooks/useCustomTheme";
import { setInterviews, setIsSlim } from "store/mainSlice";
import Avatar from "components/Avatar";
import { getInterviewDate, INTERVIEW_STATUS, showErrorMessage } from "Utils";
import FinishedIcon from "assets/images/sidebar/Finished Icon.svg";
import NotFinishedIcon from "assets/images/sidebar/Not Finished Icon.svg";
import CalendarIcon from "assets/images/sidebar/Calendar Icon.svg";
// import MoreIcon from "assets/images/sidebar/More Options Icon.svg";
import TrashIcon from "assets/images/sidebar/trash-01.svg";
import axios from "axios";
import { SERVER_URL } from "api";
import JessicaLogo from "assets/images/meeting/meeting-jessica.png";
import Tooltip from "components/Tooltip/Tooltip";

const Sidebar = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isSlim } = useCustomTheme();
  const [isOpened, setIsOpened] = useState(true);
  const [deletingIndex, setDeletingIndex] = useState(-1);
  const [willDeleteIndex, setWillDeleteIndex] = useState(-1);
  // const [top, setTop] = useState(0);
  // const [left, setLeft] = useState(0);
  const moreMenuRef = useRef(null);
  //const [interviews, setInterviews] = useState([]);
  const { profile } = useUserInfo();
  const { picture, interviews, firstName } = profile;
  const { interviewId } = useInterview();
  // const monthOptions = [
  //   { value: 0, label: "January" },
  //   { value: 1, label: "February" },
  //   { value: 2, label: "March" },
  //   { value: 3, label: "April" },
  //   { value: 4, label: "May" },
  //   { value: 5, label: "June" },
  //   { value: 6, label: "July" },
  //   { value: 7, label: "August" },
  //   { value: 8, label: "September" },
  //   { value: 9, label: "October" },
  //   { value: 10, label: "November" },
  //   { value: 11, label: "December" },
  // ];
  const toggleSlimMode = () => {
    dispatch(setIsSlim(!isSlim));
    if (isSlim) {
      setIsOpened(false);
    } else {
      setIsOpened(true);
    }
  };

  useEffect(() => {
    if (isSlim) {
      setIsOpened(false);
    } else {
      setIsOpened(true);
    }
  }, [isSlim]);

  const slimOnMobile = useCallback(() => {
    if (window.innerWidth <= 768) {
      dispatch(setIsSlim(true));
    }
  }, [dispatch]);

  const barRef = useRef(null);

  useEffect(() => {
    const closeSidebar = (event) => {
      if (barRef.current && !barRef.current.contains(event.target))
        slimOnMobile();
    };
    window.addEventListener("click", closeSidebar);
    return () => {
      window.removeEventListener("click", closeSidebar);
    };
  });

  const toggleMenuItem = () => {
    setIsOpened((prev) => !prev);
  };

  // const openMore = (event, index) => {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   const rect = event.currentTarget.getBoundingClientRect();

  //   // Calculate the top and left positions relative to the viewport
  //   const top = rect.top;
  //   const left = rect.left;
  //   console.log(top, left);
  //   setWillDeleteIndex(index);
  //   setTop(top);
  //   setLeft(left);
  // };

  const closeMore = useCallback(() => {
    setWillDeleteIndex(-1);
  }, []);

  useEffect(() => {
    const handleMenu = (e) => {
      if (moreMenuRef.current && !moreMenuRef.current.contains(e.target)) {
        closeMore();
      }
    };
    window.addEventListener("click", handleMenu);
    return () => {
      window.removeEventListener("click", handleMenu);
    };
  }, [closeMore]);

  // const deleteInterview = async () => {
  //   if (willDeleteIndex < 0 || willDeleteIndex === deletingIndex) return;
  //   setDeletingIndex(willDeleteIndex);
  //   try {
  //     await axios.delete(
  //       `${SERVER_URL}/interview-sessions/${interviews[willDeleteIndex]._id}/remove`
  //     );
  //     closeMore();
  //     if (interviews[willDeleteIndex]._id === interviewId)
  //       navigate("/schedule");
  //     dispatch(
  //       setInterviews(
  //         interviews.filter(
  //           (interview) => interview._id !== interviews[willDeleteIndex]._id
  //         )
  //       )
  //     );
  //   } catch (error) {
  //     console.log(error);
  //     showErrorMessage(
  //       "Failed to delete the interview session. Please try again."
  //     );
  //   }
  //   setDeletingIndex(-1);
  // };
  const deleteInterview = async (e, index) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (index < 0 || index === deletingIndex) return;
    setDeletingIndex(index);
    try {
      await axios.delete(
        `${SERVER_URL}/interview-sessions/${interviews[index]._id}/remove`
      );
      closeMore();
      if (interviews[index]._id === interviewId)
        navigate("/schedule");
      dispatch(
        setInterviews(
          interviews.filter(
            (interview) => interview._id !== interviews[index]._id
          )
        )
      );
    } catch (error) {
      console.log(error);
      showErrorMessage(
        "Failed to delete the interview session. Please try again."
      );
    }
    setDeletingIndex(-1);
  };

  return (
    <div className={`sidebar ${isSlim ? "slim" : ""}`} ref={barRef}>
      <div className="sidebar-box scroll-dark">
        <div className="icon-container top-0" onClick={toggleSlimMode}>
          {isSlim ? (
            <img
              src={sidebarIcon}
              alt="Slim Logo"
              className="slim-logo flex-shrink-0"
            />
          ) : (
            <div className="full-logo-wrapper">
              <img src={burgerIcon} alt="Burger Icon" className="burger-icon" />
              <img src={fullLogo} alt="Full Logo" className="full-logo" />
            </div>
          )}
        </div>
        <div className="sidebar-menu-list relative">
          <div className="sidebar-menu-item">
            <div className={`sidebar-menu-item-header ${isSlim ? "slim" : ""}`}>
              <div className="sidebar-menu-item-title">
                <img
                  src={interviewIcon}
                  alt="Interview Icon"
                  className="sidebar-menu-item-icon"
                />
                {!isSlim && (
                  <p className="sidebar-menu-item-text">My Interviews</p>
                )}
              </div>
              {!isSlim && (
                <div
                  className="sidebar-menu-item-expander"
                  onClick={toggleMenuItem}
                >
                  {isOpened ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                </div>
              )}
            </div>

            {isOpened && (
              <div className="sidebar-menu-item-content">
                {interviews &&
                  interviews.map((interview, index) => {
                    const guestMode = interview.guestMode;
                    return (
                      // <Link
                      //   to={
                      //     interview.status === INTERVIEW_STATUS.DONE
                      //       ? "/posts/" + interview._id
                      //       : "/meeting/" + interview._id
                      //   }
                      //   className="sidebar-menu-item-child-link"
                      //   onClick={() => {
                      //     dispatch(setFetchedPosts(false));
                      //     dispatch(initInterviewData());
                      //     slimOnMobile();
                      //   }}
                      //   key={interview._id}
                      // >
                      //   <div
                      //     className={`sidebar-menu-item-child ${
                      //       interviewId === interview._id ? "active" : ""
                      //     }`}
                      //   >
                      //     <p className="sidebar-menu-item-child-text">
                      //       {interview.topic}
                      //     </p>
                      //     <p className="sidebar-menu-item-child-date">
                      //       {`Created: ${getInterviewDate(
                      //         interview.schedule
                      //       )} / ${
                      //         interview.status === INTERVIEW_STATUS.DONE
                      //           ? "Finished"
                      //           : "Not Finished"
                      //       }`}
                      //     </p>
                      //   </div>
                      // </Link>
                      <Link
                        to={
                          interview.status === INTERVIEW_STATUS.DONE
                            ? "/posts/" + interview._id
                            : "/meeting/" + interview._id
                        }
                        className="sidebar-menu-item-child-link"
                        onClick={() => {
                          dispatch(setFetchedPosts(false));
                          dispatch(initInterviewData());
                          slimOnMobile();
                        }}
                        key={interview._id}
                      >
                        <div
                          className={`py-2.5 px-[14px] flex gap-2 cursor-pointer items-center justify-between ${
                            interviewId === interview._id
                              ? "sidebar-menu-active-child"
                              : ""
                          }`}
                        >
                          <div className="flex flex-col gap-2 cursor-pointer items-start">
                            <div className="sidebar-menu-item-child-text flex items-center gap-1">
                              <div className="flex items-center justify-center flex-shrink-0">
                                <Tooltip
                                  text={
                                    guestMode
                                      ? guestMode?.guest?.firstName
                                      : firstName
                                  }
                                  className="flex-shrink-0"
                                >
                                  <Avatar
                                    className="avatar size-8"
                                    src={
                                      guestMode
                                        ? guestMode?.guest?.picture
                                        : picture
                                    }
                                    alt="user"
                                  />
                                </Tooltip>
                                <Tooltip
                                  text={
                                    guestMode
                                      ? guestMode?.assistant?.name
                                      : "Jessica"
                                  }
                                  className="flex-shrink-0 -ml-2"
                                >
                                  <Avatar
                                    className="avatar size-8"
                                    src={
                                      guestMode?.assistant?.picture ||
                                      JessicaLogo
                                    }
                                    alt="assistant"
                                    defaultUser={JessicaLogo}
                                  />
                                </Tooltip>
                              </div>
                              <span>{interview.topic}</span>
                            </div>
                            <div className="sidebar-menu-item-child-date flex items-center gap-3 flex-wrap">
                              <div className="flex items-center gap-1">
                                {interview.status === INTERVIEW_STATUS.DONE ? (
                                  <>
                                    <img
                                      src={FinishedIcon}
                                      alt="Finished"
                                      className="flex-shrink-0  w-4 h-4"
                                    />
                                    <span className="flex-shrink-0">
                                      Finished
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={NotFinishedIcon}
                                      alt="Finished"
                                      className="flex-shrink-0  w-4 h-4"
                                    />
                                    <span className="flex-shrink-0">
                                      Not Finished
                                    </span>
                                  </>
                                )}
                              </div>
                              <div className="flex items-center gap-1">
                                <img
                                  src={CalendarIcon}
                                  alt="date"
                                  className="flex-shrink-0  w-4 h-4"
                                />
                                <span className="flex-shrink-0">{`Created: ${getInterviewDate(
                                  interview.schedule
                                )}`}</span>
                              </div>
                            </div>
                          </div>
                          <img
                            src={TrashIcon}
                            alt="more"
                            className={`flex-shrink-0 size-5 more-btn opacity-80 ${
                              interviewId === interview._id ? "block" : "hidden"
                            }`}
                            onClick={(e) => deleteInterview(e, index)}
                            // onClick={(e) => openMore(e, index)}
                          />
                        </div>
                      </Link>
                    );
                  })}
              </div>
            )}
          </div>
          <div className="sidebar-menu-item sticky bottom-0">
            <div className={`sidebar-menu-item-header ${isSlim ? "slim" : ""}`}>
              <div
                className={`sidebar-menu-item-title ${isSlim ? "" : "profile"}`}
              >
                <Avatar
                  src={picture}
                  alt="Profile Icon"
                  className="sidebar-menu-profile-user bg-slate-700"
                />
                {!isSlim && (
                  <img
                    src={iconJessica}
                    alt="Profile Icon"
                    className="sidebar-menu-profile-jessica"
                  />
                )}
              </div>
              {!isSlim && (
                <Link
                  to="/schedule"
                  className="sidebar-menu-schedule-call"
                  onClick={() => {
                    slimOnMobile();
                  }}
                >
                  <p className="sidebar-menu-schedule-call-text">
                    Schedule Call
                  </p>
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="bg-black border border-gray-500 fixed w-[260px] px-4 py-2 rounded-[16px] gap-[14px] items-center justify-start flex-nowrap z-[1000] text-white cursor-pointer"
        style={{
          // top: top + 24,
          // left: left - 260 + 24,
          display: willDeleteIndex > -1 ? "flex" : "none",
        }}
        ref={moreMenuRef}
        onClick={deleteInterview}
      >
        <img src={TrashIcon} alt="trash" className="m-[9px]" />
        <span>
          {deletingIndex !== willDeleteIndex
            ? "Delete interview"
            : "Deleting ..."}
        </span>
      </div>
    </div>
  );
};

export default Sidebar;
